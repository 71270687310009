import React, { Component } from 'react'
import styled from '@emotion/styled/macro'

const CrownContainer = styled('svg')`
  opacity: 1 !important;
  margin-left: 0px !important;
  margin-right: 5px;
`

const Crown = ({ className, onClick, onMouseOver, onMouseLeave, active}) => {

  return (
    <CrownContainer
      width="25"
      height="25"
      className={className}
      onClick={onClick}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
    >
      <g
        id="Designs"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Records-V1"
          transform="scale(1.4) translate(0, 0)"
          fill={active ? '#99751A':'#ADBBCD'}
          width={"20px"}
          height={"20px"}
          // width={"30px"}
          // height={"30px"}
        >
          <path
            d="M5.04348 7.5L0 3L2.26087 16H14.2609L16 3L11.3043 7.5L8 0L5.04348 7.5Z"
            id="crown-icon"
          />
        </g>
      </g>
    </CrownContainer>
  )
}

export default Crown

// class Crown extends Component {
//   render() {
//     const { className, onClick, onMouseOver, onMouseLeave, active} = this.props
//
//     return (
//       <CrownContainer
//         width="16"
//         height="16"
//         className={className}
//         onClick={onClick}
//         onMouseOver={onMouseOver}
//         onMouseLeave={onMouseLeave}
//       >
//         <g
//           id="Designs"
//           stroke="none"
//           strokeWidth="1"
//           fill="none"
//           fillRule="evenodd"
//         >
//           <g
//             id="Records-V1"
//             transform="translate(0, 0)"
//             fill={active ? '#99751A':'#ADBBCD'}
//           >
//             <path
//               d="M5.04348 7.5L0 3L2.26087 16H14.2609L16 3L11.3043 7.5L8 0L5.04348 7.5Z"
//               id="crown-icon"
//             />
//           </g>
//         </g>
//       </CrownContainer>
//     )
//   }
// }
