import React from 'react'
import styled from '@emotion/styled/macro'

import warningImage from '../../assets/warning.svg'

const ErrorContainer = styled('div')`
  color: #E8EBFC;
  padding: 40px 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 400px;
  margin: 0 auto 0;
`

const Warning = styled('img')`
  width: 40px;
`

const H2 = styled('h2')`
  font-family: Overpass;
  font-weight: 300;
  font-size: 28px;
  color: #E8EBFC;
  text-align: center;
`

const Button = styled('button')`
  background: #E8EBFC;
  border-radius: 6px;
  border: none;
  color: #1A1A1A;
  font-family: Overpass;
  font-weight: 300;
  font-size: 16px;
  padding: 10px 20px;
  margin-top: 20px;
  cursor: pointer;
  &:hover {
    transition: all 0.2s ease-in-out;
    transform: scale(1.05);
  }
`

const Message = styled('div')``

async function handleClick() {
  try {
    await ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: '0x288' }], // Hexadecimal version of 648, prefixed with 0x
    });
    await window.location.reload();
  } catch (error) {
    console.log(error.code);
    if (error.code === 4902) {
      await window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainId: '0x288',
            chainName: 'Endurance Mainnet',
            nativeCurrency: {
              name: 'ACE',
              symbol: 'ACE',
              decimals: 18,
            },
            rpcUrls: ['https://rpc-endurance.fusionist.io'],
            blockExplorerUrls: ['https://explorer-endurance.fusionist.io/'],
          },
        ],
      })
      await window.location.reload();
    }
  }


}

export const NetworkError = ({ message }) => (
  <ErrorContainer data-testid="network-error">
    <Message>
      <Warning src={warningImage} />
      <H2>{message}</H2>
      <br />
      Please change your dApp browser to Endurance Mainnet and reload the page.
    </Message>
    <Button onClick={async (e)=> {
      e.preventDefault()
      await handleClick()
    }}>
      Add Endurance Chain to MetaMask
    </Button>
  </ErrorContainer>
)

export const Error404 = () => (
  <ErrorContainer>
    <Message>
      <Warning src={warningImage} />
      <H2>404</H2>
      We couldn't find what you were looking for!
    </Message>
  </ErrorContainer>
)
