import React, { useEffect } from 'react'
import moment from 'moment/moment'
import { useMutation, useQuery } from '@apollo/client'
import {
  GET__AIRDROP_HISTORY, GET__SUCCESS_HISTORY,
  GET_AIRDROP_EPOCH,
  GET_POOL_BALANCE,
  GET_REGISTRATIONS_SUBGRAPH,
  GET_SUCCESS_RATE_AIRDROP
} from '../../graphql/queries'
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import './style.css'
import { ethers } from '@ace_domains/ui'
import styled from '@emotion/styled'
import { CLAIM_AIRDROP } from '../../graphql/mutations'

const StyledButton = styled('button')`
  width: 300px;
  height: 40px;
  background: #F0C17D;
  //box-shadow: inset 0px 0px 5px 4px #F0C17D;
  border-radius: 5px;
  border: 2px solid #99751A;
  color: #36174D;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  font-style: normal;
  margin-bottom: 25px;

  &:disabled {
    background: transparent;
    color: #F0C17D;
    border: 2px solid #99751A;
  }
`

function useDomains({
                      domainType,
                      address,
                    }) {

  let currentDate = moment()
  if(address !== '') {
    const registrationsQuery = useQuery(GET_REGISTRATIONS_SUBGRAPH, {
      variables: {
        id: address,
        first: 500,
        skip: 0,
        orderBy: 'labelName',
        orderDirection: 'asc',
        expiryDate: currentDate.subtract(0, 'days').unix()
      },
      skip: domainType !== 'registrant',
      fetchPolicy: 'no-cache'
    })
    return registrationsQuery
  }
  return []
}

const DomainListAirdrop = ({address}) => {
  let success = 0;
  let epoch = 0;
  let poolBalance = 0;
  const defaultOption = null;
  const [selectedDomain, setSelectedDomain] = React.useState(null)
  const [waiting, setWaiting] = React.useState(false)
  const options = [];
  const initialPoolRewards = [5000, 2001, 2001, 2001, 2001, 2001, 1947, 1944, 1944, 1944, 1944, 1944, 1944, 1944, 1944, 1944, 1944, 1944, 1944, 1944, 1944, 1944, 1944, 1944];

  const { loading, data, error, refetch } = useDomains({
    domainType: 'registrant',
    address: address.toLowerCase(),
  })

  // if(loading) return (<div>Loading...</div>)
  if(!loading){
    data.account.registrations.sort((a, b) => {
      return a.domain.labelName.length > b.domain.labelName.length ? 1 : -1
    });
    for(let i = 0; i < data.account.registrations.length; i++) {
      // console.log(data.account.registrations[i].domain)
      const labelHash = ethers.utils.keccak256(ethers.utils.toUtf8Bytes(data.account.registrations[i].domain.labelName))
      const tokenId = ethers.BigNumber.from(labelHash).toString();

      options.push({
        label: data.account.registrations[i].domain.labelName + ".ace",
        value: tokenId,
      })
    }
  }

  const { data: successRateQuery } = useQuery(GET_SUCCESS_RATE_AIRDROP, {
    variables: {
      name: selectedDomain ? selectedDomain.label.split(".")[0] : "00",
    }
  })

  const { data: airdropClaimHistory, refetch: refetchHistory } = useQuery(GET__AIRDROP_HISTORY, {
    variables: {
      tokenId: selectedDomain ? selectedDomain.value : "00",
    }
  })

  const { data: airdropClaimSuccess, refetch: refetchSuccess } = useQuery(GET__SUCCESS_HISTORY, {
    variables: {
      tokenId: selectedDomain ? selectedDomain.value : "00",
    }
  })

  const { data: currentEpoch, loading: epochLoading, error: epochError } = useQuery(GET_AIRDROP_EPOCH)
  const { data: currentPoolBalance, loading: balanceLoading, refetch: refetchPoolBalance } = useQuery(GET_POOL_BALANCE)

  if(currentPoolBalance?.getPoolBalance) {
    poolBalance = Number(ethers.utils.formatUnits(currentPoolBalance?.getPoolBalance, "ether"));
  }

  if(currentEpoch?.getEpoch) {
    epoch = Number(ethers.utils.formatUnits(currentEpoch?.getEpoch, "wei"))+1;
  }

  if(successRateQuery?.getAirdropRate) {
    success = Number(ethers.utils.formatUnits(successRateQuery?.getAirdropRate, "wei"))/115.79;
  }

  const [mutation] = useMutation(CLAIM_AIRDROP, {
    onCompleted: data => {
      console.log("mutation Data", data.claimAirdrop)
      if(data.claimAirdrop.txState === "Failed"){
        alert(`${data.claimAirdrop.errorMessage}`)
      }
    },
  })

  function handleClaim() {
    setWaiting(true)
    mutation({
      variables: {
        domainName: `${selectedDomain.label.split(".")[0]}`,
        domainId: `${selectedDomain.value}`,
      }
    }).then((res) => {
      const randomNumber = parseInt(res.data.claimAirdrop.events[0].args[3], 10)
      const successRate = parseInt(res.data.claimAirdrop.events[0].args[4], 10)
      if(parseInt(res.data.claimAirdrop.events[0].args[6], 10) === 0){
        alert(`Sorry, you didn't win the Lucky Draw ! Try again!\nYour random number was ${((randomNumber/1.1579)/100).toFixed(2)} and the success rate was ${((successRate/1.1579)/100).toFixed(2)}%, being lower than 100% (${((randomNumber+successRate)/1.1579/100).toFixed(2)}%)`)
      }else{
        alert(`Congrats! You claimed successfully your Airdrop! 🎉\nYour random number was ${((randomNumber/1.1579)/100).toFixed(2)} and your total winning rate was ${((randomNumber+successRate)/1.1579/100).toFixed(2)}%`)
      }

      refetchHistory().then(() => {
        console.log("History re-fetched")
      }).catch((e) => {
        console.log("Error", e)
      })

      refetchSuccess().then(() => {
        console.log("Success re-fetched")
      }).catch((e) => {
        console.log("Error", e)
      })

      refetchPoolBalance().then(() => {
        console.log("Pool Balance re-fetched")
      }).catch((e) => {
        console.log("Error", e)
      })

    }).catch((e) => {
      console.log("Error", e)
    }).finally(() => {
      setWaiting(false)
    })
  }

  return (
    <div style={{color: "white", display: "flex", flexDirection: "column", alignItems:"center"}}>
      <div style={{width: "300px", marginTop: "50px", marginBottom: "25px"}}>
        <Dropdown
          options={options}
          value={defaultOption}
          onChange={(e)=> setSelectedDomain(e)}
          placeholder="Select your AceDomain"
          arrowClassName="myArrowClassName"
          placeholderClassName='myPlaceholderClassName'
          controlClassName='myControlClassName'
        />
      </div>

      {selectedDomain && success !== 0 && (
        <div style={{marginBottom: "25px", width: "300px"}}>
          <p style={{padding: "8px", border: "2px solid #99751A", borderRadius: "5px", textAlign:"center"}}>Success Rate: {success.toFixed(2)} %</p>
        </div>
      )}

      {selectedDomain && airdropClaimHistory?.getAirdropHistory && epoch && (
        <StyledButton
          disabled={airdropClaimHistory?.getAirdropHistory[epoch-1].result === true}
          onClick={()=> handleClaim()}
        >
          {airdropClaimHistory?.getAirdropHistory[epoch-1].result === true ? "Already Drawn" : "Draw"}
        </StyledButton>
      )}

      {!balanceLoading && poolBalance && (
        <div>
          Reward Info:
          <div style={{marginBottom: "25px", width: "300px", border: "2px solid #99751A", borderRadius: "5px", }}>
            <p style={{padding: "4px", marginLeft:"10px", textAlign:"left"}}>Current Period: {epoch}</p>
            <p style={{padding: "4px", marginLeft:"10px", textAlign:"left"}}>Initial Supply: {initialPoolRewards[epoch-1]} ACE</p>
            <p style={{padding: "4px", marginLeft:"10px", textAlign:"left"}}>Remaining ACE: {poolBalance} ACE</p>
          </div>
        </div>
      )}

      {selectedDomain && airdropClaimSuccess?.getSuccessHistory && airdropClaimHistory?.getAirdropHistory && (
        <div style={{marginTop: "50px"}}>
          <div style={{display: "flex" }}>
            <div style={{display: "flex", flexDirection: "column"}}>
              <div style={{padding: "5px"}}> Period: </div>
              {/*<div style={{padding: "5px"}}> Drawn: </div>*/}
              <div style={{padding: "5px"}}> Drawn: </div>
            </div>
            {airdropClaimHistory?.getAirdropHistory.map((claim, index) => {
              return (
                <div key={index} style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", alignContent:"center"}}>
                  <div style={{marginLeft: "2px", padding: "4px 6px", backgroundColor: epoch === claim.epoch + 1 ? "#77B255" : "transparent", color: "white", borderRadius: "3px" }}>{claim.epoch+1}</div>
                  {/*<div style={{marginTop: "5px", marginLeft: "2px", padding: "4px 6px", color: "white", borderRadius: "5px" }}>{claim.result ? "✅" : "❌"}</div>*/}
                  <div style={{marginLeft: "2px", padding: "4px 6px", color: "white", borderRadius: "5px" }}>{
                    claim.result
                      ? airdropClaimSuccess.getSuccessHistory[index].result
                        ? "✅"
                        : "❌"
                      : "❔"
                  }</div>
                </div>
              )
            })}
          </div>
        </div>)}
    </div>
  )
}

export default DomainListAirdrop
