import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled/macro'
import moment from 'moment'
import { useQuery } from '@apollo/client'
import { GET_REGISTRATIONS_SUBGRAPH } from '../graphql/queries'
import gql from 'graphql-tag'
import DomainList from '../components/Airdrop/DomainListAirdrop'


const Header = styled('div')`
  font-size: 32px;
  font-weight: 300;
  color: #fff;
  width: 100%;
  display: flex;
  text-align: center;

  justify-content: center;
`

const SubHeader = styled('div')`
  font-size: 18px;
  font-weight: 300;
  color: #fff;
  width: 75%;
  text-align: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`

const SIDENAV_QUERY = gql`
    query getSideNavData {
        accounts
        isReadOnly
    }
`

const Airdrop = () => {

  const {
    data: { accounts, isReadOnly }
  } = useQuery(SIDENAV_QUERY)

  const [epochStarted, setEpochStarted] = useState(false)

  useEffect(() => {
    (async()=> {
      const timeStamp = await fetch('http://worldtimeapi.org/api/timezone/Europe/Madrid')
      const unixTimestamp = (await timeStamp.json()).unixtime
      if(unixTimestamp > 1702868400) {
        setEpochStarted(true)
      }
    })()
  }, [])

  return (
    <div>
      <Header>
        ACE Airdrop for AceDomains Holders!
      </Header>
      <hr />
      <SubHeader>
        <p>
          We are excited to announce that we will be airdropping 50,000 ACE tokens to AceDomains holders!
        </p>
        <p>
          The airdrop will be distributed based on a lucky draw. The lower the chars the domain has, the higher the chance to win the airdrop.
        </p>
      </SubHeader>

      {isReadOnly && (
        <SubHeader>
          <p>
            You are currently connected to a read-only network. Please connect to a network where you can sign transactions to claim your airdrop.
          </p>
        </SubHeader>
      )}

      {!isReadOnly && (
        <DomainList address={accounts[0]} />
      )}

    </div>
  )
}

export default Airdrop
