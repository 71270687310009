import { injectGlobal } from 'emotion'

injectGlobal`
  * {
    box-sizing: border-box;
  }
  body {
    font-family: Overpass,sans-serif;
    background:  linear-gradient(270deg, rgba(54,23,77,1) 25%, rgba(0,60,120,1) 75%);
    margin: 0;
  }

  a {
    color: #5284ff;
    text-decoration: none;
    transition: 0.2s;

    &:hover {
      color: #2C46A6;
    }

    &:visited {
      color: #5284ff
    } 
  }
`
