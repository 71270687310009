import React, { Component } from 'react'
import styled from '@emotion/styled/macro'

class MarketPlace extends Component {
  render() {
    const { className, onClick, onMouseOver, onMouseLeave } = this.props

    return (
      <MarketPlaceContainer
        width="18"
        height="18"
        className={className}
        onClick={onClick}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
      >
        <g
          id="Designs"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="Records-V1"
            transform="translate(-6.50000, -8.000000)"
            fill="#ADBBCD"
          >
            <path
              d="m 25.148131,13.504539 a 0.69259117,0.69259117 0 0 0 -0.07275,-0.120767 2.2887519,2.2887519 0 0 0 -0.08585,-0.307737 c -0.0044,-0.01164 -0.0087,-0.02328 -0.01455,-0.03492 L 22.972144,8.4330553 A 0.72751174,0.72751174 0 0 0 22.305016,7.9965483 H 9.2098039 A 0.72751174,0.72751174 0 0 0 8.5426756,8.4330553 L 6.5274681,13.075307 a 2.0668608,2.0668608 0 0 0 0,1.515407 3.4556807,3.4556807 0 0 0 1.9548241,1.851518 v 7.559574 a 0.72751174,0.72751174 0 0 0 0.7275117,0.727512 H 22.305016 a 0.72751174,0.72751174 0 0 0 0.727511,-0.727512 v -7.559574 a 3.4549532,3.4549532 0 0 0 1.954824,-1.850063 1.9017157,1.9017157 0 0 0 0.118585,-0.47652 0.76897991,0.76897991 0 0 0 0.04219,-0.61111 z m -11.573256,9.769755 v -4.36507 h 1.455023 v 4.36507 z m 2.910047,0 v -4.36507 h 1.455023 v 4.36507 z m 5.092582,0 h -2.182535 v -5.092582 a 0.72751174,0.72751174 0 0 0 -0.727512,-0.727511 h -5.820094 a 0.72751174,0.72751174 0 0 0 -0.727512,0.727511 v 5.092582 H 9.9373156 v -6.547605 a 3.783061,3.783061 0 0 0 2.9100474,-1.309521 3.8841852,3.8841852 0 0 0 5.820094,0 3.783061,3.783061 0 0 0 2.910047,1.309521 z m 2.063951,-9.239399 a 2.1934479,2.1934479 0 0 1 -2.063951,1.23677 2.0283027,2.0283027 0 0 1 -2.182535,-1.818779 0.727512,0.727512 0 0 0 -1.455024,0 2.0283027,2.0283027 0 0 1 -2.182535,1.818779 2.0283027,2.0283027 0 0 1 -2.182535,-1.818779 0.7275119,0.7275119 0 0 0 -1.455024,0 2.0283027,2.0283027 0 0 1 -2.1825354,1.818779 2.1941754,2.1941754 0 0 1 -2.0646783,-1.23677 0.60819981,0.60819981 0 0 1 0,-0.415409 L 9.6870516,9.4515717 H 21.827768 l 1.802046,4.1468163 a 0.62420507,0.62420507 0 0 1 0.01164,0.436507 z"
              id="market-icon"
            />
          </g>
        </g>
      </MarketPlaceContainer>
    )
  }
}

const MarketPlaceContainer = styled('svg')`
  opacity: 1 !important;
  margin-left: 0px !important;
  margin-right: 5px;
`

export default MarketPlace
