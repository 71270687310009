import React from 'react'
import styled from '@emotion/styled'
import Icon from './IconBase'
import { FaParachuteBox } from 'react-icons/fa'


const SVG = styled(Icon)`
`

const GiftIcon = ({ active, color, scale=0.1, className }) => {
  return (
    <SVG  width="23" height="21" active={active} className={className}>
      <FaParachuteBox/>
    </SVG>
  )
}

export default GiftIcon
